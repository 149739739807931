
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";

export default defineComponent({
  name: "CZPositionSummary",

  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Position Summary", ["Flexible Procurement"]);
    });

    const chartOptions = {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Budget Cost", "Market Cost", "MTM Cost"],
      },
    };

    const series = reactive([{}]);
    series.splice(0);
    let AccountId = store.getters.AccountId;
    let current = store.getters.layoutConfig("flexibleProcurement.current");
    let FPreportName = store.getters.layoutConfig(
      "flexibleProcurement.reportName"
    );
    let FPscenarioName = store.getters.layoutConfig(
      "flexibleProcurement.scenarioName"
    );
    const accountInfo: any = {
      clientId: AccountId,
      productId: 4,
      reportId: FPreportName,
      scenarioId: FPscenarioName,
      iscurrent: current,
    };
    ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
      .then(async ({ data }) => {
        const response = await data;
        //Makeing the series with dynamic values
        const datas = {
          data: [
            response.costSummary.budget,
            response.costSummary.market,
            response.costSummary.mtm,
          ],
        };
        series.push(datas);
      })
      .catch((a) => {
        console.log("Api Log Error", a);
      });

    return {
      series,
      chartOptions,
    };
  },
});
